
import {
    Box,
    Button,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainLoyOut from "../../components/main-layout";
import AnalyticsEmpty from "./AnalyticsEmpty";
import AnalyticsContainer from "./AnalyticsContainer";
import { getApiCallDynamic } from "../../services/api-service";
import { API } from "../../services/api-config";
import useInput from "../../helpers/use-input";
import { Search } from "@mui/icons-material";
import { kstheme } from "../../theme/theme";
import { useRecoilState } from "recoil";
import { analyticsDataState } from "../../recoil/profile-atom";

export default function AnalyticsMain(props) {
    const [value, setValue] = useState(null);
    const [searchHide, setSearchHide] = useState(false);
    console.log("Initial props:", props);
    const [propsData, setPropsData] = useState(props.user.data);
    console.log("Final props:", propsData);
    const [searchOptions, setSearchOptions] = useState([]);
    const [userInfo, setUserInfo] = useState(null);

    const isTab = useMediaQuery(kstheme.breakpoints.down("md"));
    const [hideResult, setHideResult] = useState(true);
    const [searchItem] = useInput({
        data: null,
        status: false,
        initial: null,
    });

    useEffect(() => {
        if (searchItem.value) {
            userList(searchItem.value);
        }
        if (hideResult && searchItem.value) {
            setHideResult(false);
        }
    }, [searchItem.value]);

    const userList = (v) => {
        let params = { globalFilter: v, start: 0, size: 50 };



    };




    let [analyticdata, setAnalyticData] = useState(null)
    let [user, setUser] = useState(null)

    const keyDownHandler = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            setHideResult(true);
        }
    };

    useEffect(() => {
        if (props) {
            setPropsData(props);
            setUser(props.user.data.userCode)
        }
    }, [props]);






    return (
        <MainLoyOut>
            <Box py={5} px={{ xs: 1.5, md: 15 }}>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>

                    </Grid>

                </Grid>
                <Box mt={4}>
                    {searchOptions.length === 0 ? (
                        <AnalyticsContainer />
                    ) : (
                        <AnalyticsContainer />
                    )}

                </Box>

            </Box>
        </MainLoyOut>
    );
}
