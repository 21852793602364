
import React from 'react';
import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';
import DownloadChartWrapper from '../DownloadChartWrapper';

const BAR_SIZE = 25;

const CustomGroupedBarType = ({
    data,
    colors = {
        CONVENTIONAL: '#82ca9d', // Green
        FHA: '#8884d8', // Blue
        VA: '#ff7300', // Orange
        USDA: '#ff8042', // Yellow
        OTHER: '#A28CC0', // Purple
    },
    leftYAxisLabel = "# of Applications",
    rightYAxisLabel = "% of Applications",
    leftYAxisDomain = [0, 'dataMax'],
    rightYAxisDomain = [0, 100],
}) => {
    const loanCategories = Array.from(new Set(data?.map((item) => item.loanCategory)));
    const monthYearCombinations = Array.from(
        new Set(data?.map((item) => `${item.loanMonth}/${item.loanYear}`))
    );

    const chartData = monthYearCombinations.map((monthYear) => {
        const [loanMonth, loanYear] = monthYear.split('/');
        const entry = {
            name: `${new Date(loanYear, loanMonth - 1).toLocaleDateString('en-US', { month: 'short', year: '2-digit' })}`,
        };

        let totalLoans = 0;
        const categoryCounts = {};

        loanCategories.forEach((category) => {
            const filteredData = data.filter(
                (item) =>
                    item.loanMonth === loanMonth &&
                    item.loanYear === loanYear &&
                    item.loanCategory === category
            );

            const totalValue = filteredData.reduce((sum, item) => sum + item.value1, 0);
            categoryCounts[category] = totalValue;
            totalLoans += totalValue;
        });

        loanCategories.forEach((category) => {
            entry[category] = categoryCounts[category] || 0;
            entry[`${category}(%)`] = totalLoans > 0 ? ((categoryCounts[category] / totalLoans) * 100).toFixed(2) : null;
        });

        entry.totalLoans = totalLoans;
        return entry;
    });

    const customTooltip = ({ payload, label }) => {
        if (payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div style={{ backgroundColor: '#fff', padding: '10px', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                    <p><strong>{data.name}</strong></p>
                    <p><strong>Total Loans:</strong> {data.totalLoans}</p>
                    {loanCategories.map((category, index) => (
                        data[category] > 0 && (
                            <p key={index} style={{ color: colors[category.toUpperCase()] || '#000' }}>
                                <strong>{category}:</strong> {data[category]} ({data[`${category}(%)`]}%)
                            </p>
                        )
                    ))}
                </div>
            );
        }
        return null;
    };

    const chartComponent = (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />

                <YAxis
                    yAxisId="left"
                    label={{
                        value: leftYAxisLabel,
                        angle: -90,
                        position: 'left',
                        offset: 10,
                        style: { fontWeight: 'bold', textAnchor: 'middle' }
                    }}
                    domain={leftYAxisDomain}
                    allowDecimals={false}
                    tickFormatter={(value) => Math.round(value)}
                />

                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={(value) => `${value}%`}
                    domain={rightYAxisDomain}
                    label={{
                        value: rightYAxisLabel,
                        angle: 90,
                        position: 'right',
                        offset: 10,
                        style: { fontWeight: 'bold', textAnchor: 'middle' }
                    }}
                />
                <Tooltip content={customTooltip} />
                <Legend />
                {loanCategories.map((category) => {
                    const categoryColor = colors[category.toUpperCase()] || '#ff7300';
                    return (
                        <Bar
                            key={category}
                            yAxisId="left"
                            dataKey={category}
                            fill={categoryColor}
                            barSize={BAR_SIZE}
                        />
                    );
                })}
                {loanCategories.map((category) => (
                    <Line
                        key={`${category}(%)`}
                        yAxisId="right"
                        type="monotone"
                        dataKey={`${category}(%)`}
                        stroke={colors[category.toUpperCase()] || '#ff7300'}
                        strokeWidth={3}
                        dot={{ r: 6, fill: colors[category.toUpperCase()] || '#ff7300' }}
                        activeDot={{ r: 8 }}
                    />
                ))}
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <DownloadChartWrapper
            chartComponent={chartComponent}
            data={chartData}
            fileName="LoansChart"
        />
    );
};

export default CustomGroupedBarType;
