
import React, { useEffect, useState } from 'react';
import { Button, Box, TextField, Typography, FormControlLabel, Switch } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';




const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Chart3 = ({ data }) => {



    // const groupedData = data.reduce((acc, item) => {
    //     const key = item.head || "Unknown"; 
    //     if (!acc[key]) {
    //         acc[key] = { name: key, value: 0 };
    //     }
    //     acc[key].value += item.value; 
    //     return acc;
    // }, {});

    const groupedData = data.reduce((acc, item) => {
        if (item.head !== null) {
            const key = item.head;
            if (!acc[key]) {
                acc[key] = { name: key, value: 0 };
            }
            acc[key].value += item.value;
        }
        return acc;
    }, {});



    const initialData = Object.values(groupedData).map(item => ({
        ...item,
        value: parseFloat(item.value.toFixed(2)),
    }));

    console.log(initialData);






    const [isRange, setIsRange] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filteredData, setFilteredData] = useState(initialData);




    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" flexDirection="column" alignItems="center" padding={2}>

                {/* Pie Chart */}
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie data={initialData} dataKey="value" outerRadius={150} fill="#8884d8" label>
                            {initialData?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>


            </Box>
        </LocalizationProvider>
    );
};

export default Chart3;
