import { Box } from '@mui/material'
import React, { useEffect } from 'react'

import DashboardMain from './DashboardMain';

const AnalyticsContainer = () => {







    return (
        <>

            <Box

            >

                <DashboardMain></DashboardMain>

            </Box>

        </>

    )
}

export default AnalyticsContainer