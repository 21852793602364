
import React from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import DownloadChartWrapper from '../DownloadChartWrapper';
const BAR_SIZE = 25;

const CustomGroupedBarChart = ({
    data,
    activeComponent,
    xAxisLabel = '',
    yAxisLeftLabel = '# of Loans',
    yAxisRightLabel = 'Rate (%)',
    bar1Key = 'approvedWithConditions',
    bar1Color = '#82ca9d',
    bar1Name = '# of Loans Approved with Conditions',
    bar2Key = 'notFunded',
    bar2Color = '#8884d8',
    bar2Name = '# of Loans Not Funded',
    lineKey = 'abandonedRate',
    lineColor = '#ff7300',
    lineName = 'Abandoned Loan Rate',
    ...props
}) => {



    let chartData

    if (activeComponent === 'Abandoned Loan Rate') {
        chartData = Object.values(
            data.reduce((acc, item) => {
                const head = item.head;

                if (!acc[head]) {
                    acc[head] = {
                        name: new Date(`${head}-01`).toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
                        fundedCount: 0,
                        rejectedCount: 0,
                        abandonedRate: 0,
                    };
                }


                acc[head].fundedCount += item.value2 || 0;
                acc[head].rejectedCount += item.value1 || 0;

                return acc;
            }, {})
        ).map(group => ({
            ...group,
            abandonedRate:
                group.fundedCount + group.rejectedCount > 0
                    ? ((group.rejectedCount / (group.fundedCount + group.rejectedCount)) * 100).toFixed(2)
                    : 0,
        }));

    } else {

        chartData = Object.values(
            data.reduce((acc, item) => {
                const head = item.head;

                if (!acc[head]) {
                    acc[head] = {
                        name: new Date(head).toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
                        [bar1Key]: 0,
                        [bar2Key]: 0,
                    };
                }

                acc[head][bar1Key] += item.value1 || 0;
                acc[head][bar2Key] += item.value2 || 0;

                return acc;
            }, {})
        ).map(group => ({
            ...group,
            [lineKey]:
                group[bar1Key] > 0
                    ? ((group[bar2Key] / group[bar1Key]) * 100).toFixed(2)
                    : 0,
        }));
    }
    //const maxRate = Math.max(...chartData.map((d) => parseFloat(d[lineKey]) || 0), 100);
    const maxRate = Math.max(100, Math.ceil((Math.max(...chartData.map((d) => parseFloat(d[lineKey]) || 0)) * 1.1) / 10) * 10);
    const chartComponent = (
        <ResponsiveContainer height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" label={{ value: xAxisLabel, position: 'bottom', offset: -10 }} />

                <YAxis
                    yAxisId="left"
                    label={{
                        value: yAxisLeftLabel,
                        angle: -90,
                        position: 'insideLeft',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                    allowDecimals={false}
                    tickFormatter={(value) => Math.round(value)}
                />

                {/* <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={value => `${value}%`}
                    domain={[0, 100]}
                    label={{
                        value: yAxisRightLabel,
                        angle: 90,
                        position: 'insideRight',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                /> */}
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={(value) => `${value}%`}
                    domain={[0, Math.ceil(maxRate / 10) * 10]}
                    label={{
                        value: yAxisRightLabel,
                        angle: 90,
                        position: 'insideRight',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                />
                <Tooltip
                    formatter={(value, name, props) => {
                        if (name === lineName) {
                            return `${value} %`;
                        }
                        return value;
                    }}
                />
                <Legend />
                <Bar
                    yAxisId="left"
                    dataKey={bar1Key}
                    fill={bar1Color}
                    barSize={BAR_SIZE}
                    name={bar1Name}
                />
                <Bar
                    yAxisId="left"
                    dataKey={bar2Key}
                    fill={bar2Color}
                    barSize={BAR_SIZE}
                    name={bar2Name}
                />
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey={lineKey}
                    stroke={lineColor}
                    strokeWidth={3}
                    dot={{ r: 6, fill: lineColor }}
                    activeDot={{ r: 8 }}
                    name={lineName}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <DownloadChartWrapper
            chartComponent={chartComponent}
            data={chartData}
            fileName="LoansChart"
        />
    );
};

export default CustomGroupedBarChart;
