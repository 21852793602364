
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384', '#36A2EB', '#FFCE56'];

const ChartLoanTerm = ({ data }) => {
    console.log("🔍 Raw Data:", data);

    if (!Array.isArray(data) || data.length === 0) {
        return <Typography>No data available</Typography>;
    }


    const groupedData = data.reduce((acc, item) => {
        const key = item.head || "NA";
        if (!acc[key]) {
            acc[key] = { name: key, value: 0 };
        }
        acc[key].value += parseFloat(item.value1) || 0;
        return acc;
    }, {});


    const processedData = Object.values(groupedData).map(item => ({
        name: item.name,
        value: item.value
    }));




    const hasValidData = processedData.some(item => item.value > 0);
    if (!hasValidData) {
        return <Typography>No valid data available</Typography>;
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart>
                    <Pie
                        data={processedData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={150}
                        label
                    >
                        {processedData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    <Tooltip />
                    <Legend />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default ChartLoanTerm;
