
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LoanTermBar = ({
    data,
    xAxisKey = 'loanCategory',

    yAxisLabel = '# of Loans',
    barColors = ['#8884d8', '#82ca9d', '#ffc658'],
}) => {



    const groupedData = {};
    data.forEach((item) => {
        const { loanCategory, head, value1 } = item;
        if (!groupedData[loanCategory]) {
            groupedData[loanCategory] = { loanCategory };
        }
        groupedData[loanCategory][head] = (groupedData[loanCategory][head] || 0) + (value1 || 0);
    });


    const processedData = Object.values(groupedData);




    const loanTerms = [...new Set(data.map((item) => item.head))];

    if (!processedData || processedData.length === 0) {
        return <div>No valid data available to display.</div>;
    }

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{
                        top: 40,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xAxisKey} label={{

                        position: 'bottom', offset: -5
                    }} />

                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'left',
                            style: { fontWeight: 'bold' },
                        }}
                        allowDecimals={false}
                        tickFormatter={(value) => Math.round(value)}
                    />
                    <Tooltip />
                    <Legend />
                    {loanTerms.map((term, index) => (
                        <Bar
                            key={term}
                            dataKey={term}
                            fill={barColors[index % barColors.length]}
                            name={term}
                            barSize={25}
                        />
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LoanTermBar;
