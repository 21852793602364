


import React, { useEffect, useState } from 'react';
import TabList from './TabList';
import GridDisplay from './GridDisplay';
import { handleGridButtonClick } from './handlers';
import Chart1 from './charts/Chart1';
import Chart2 from './charts/Chart2';
import Chart3 from './charts/Chart3';
import Chart4 from './charts/CustomPieChart';
import CustomBarChart from './charts/CustomBarChart';
import CustomPieChart from './charts/CustomPieChart';
import { getApiCallDynamic } from '../../services/api-service';
import { API } from '../../services/api-config';
import { StackedBarChart } from '@mui/icons-material';
import CustomStackedBarChart from './charts/CustomStackedBarChart';
import { ChartContainer } from './charts/ChartContainer';
import { Box, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { profileState } from '../../recoil/profile-atom';

const DashboardMain = ({ selectedUserFromAdmin, accessFromAdmin = false }) => {
    // Predefined tabs and content
    const initialTabs = [
        {
            id: 1,
            name: 'Mortgage Originator KPIs',
            content: [
                {
                    heading: 'Monthly Loan Product Analysis',
                    description: 'This reports gives analysis of monthly productivity of loans bifurcated on basis of Loan Terms & Loan Types.'
                },

                {
                    heading: 'Application Approval Rate',
                    description: 'The report tracks the monthly application approval rate for brokers. It provides the number of applications submitted, the number of applications approved, and the approval rate as a percentage. This helps measure the efficiency and success rate of loan applications submitted through brokers in a particular state.'
                },
                {
                    heading: 'Mortgage Loan Declined Rate',
                    description: 'This report calculates the rejection rate for mortgage loan applications, displaying the proportion of applications rejected out of the total submitted across different loan types, roles (brokers), and time periods (monthly). It enables management to track rejection patterns and help identify bottlenecks in the loan approval process.'
                },
                {
                    heading: 'Incomplete Loan Application Rate',
                    description: 'This report highlights trends in incomplete loan applications across brokers, states, and months. It provides stakeholders with insights into potential areas requiring process improvement or borrower support. By aggregating data and visualizing incomplete rates, decision-makers can take targeted actions to reduce inefficiencies.'

                },
                {
                    heading: 'Average Mortgage Type',
                    description: 'This report helps assess the popularity and distribution of various mortgage types by calculating their average contribution to total applications over time or within specific groups.'

                },
                {
                    heading: 'Leads',
                    description: 'The Leads Report measure the no of of total leads generated.'


                },
                {
                    heading: 'Lead Conversion Ratio',
                    description: 'The Lead Conversion Ratio measures the percentage of loan applications approved from the total leads generated. This metric is critical for evaluating the performance of sales channels and loan officers.'


                },
                {
                    heading: 'Abandoned Loan Rate',
                    description: 'This report tracks the rate of abandoned loans (Withdrawn or Closed without funding) as a percentage of total loan applications. It helps identify inefficiencies in the loan process by highlighting patterns in loan abandonment across roles, states, or loan types.'


                },
                {
                    heading: 'Average Loan Value',
                    description: `The Average Loan Value of Mortgage Loan Applications measures the average loan amount processed over a rolling time period (e.g., 30, 60, or 90 days).
This report helps identify trends in loan sizes, analyze market demand, and evaluate the impact of loan values on business performance.`


                },
                {
                    heading: 'Average Life Cycle of Mortgage Loans',
                    description: 'The Average Life Cycle of Mortgage Loan Applications shows how many days, on average, it takes to process a mortgage application from when it’s submitted to when it’s approved. It helps track how efficient the loan approval process is over a rolling time period of months.'


                },
                {
                    heading: 'Average Number of Conditions per Loan',
                    description: 'This report calculates the average number of conditions required per loan across various segments such as state, loan officer, loan type, and time period. It helps identify trends and bottlenecks in the underwriting process, providing insights for streamlining loan approval.'


                }



            ],
        },
        {
            id: 2,
            name: 'Mortgage Call Report',
            content: [
                { heading: 'Loan Category', description: 'This report provides an overview of loans based on their type, such as Conventional, FHA, VA, USDA, or other classifications. It helps Brokers, MLOs, and MLPs analyze the distribution of loan types, assess risk profiles, and track trends in borrower preferences.' },
                { heading: 'Loan Term', description: 'This report categorizes loans based on their repayment terms, such as 15-year, 20-year, 30-year, or custom terms. It helps Brokers, MLOs, and MLPs understand borrower preferences, evaluate long-term financial commitments, and make strategic decisions on loan offerings.' },
                { heading: 'Loan Category/Loan Term', description: 'The graph illustrates the distribution of loans across different loan types (Conventional, FHA, VA, and USDA) based on loan terms. It helps Brokers, MLOs, and MLPs analyze the distribution of loan types, assess risk profiles, and track trends in borrower preferences.' },
                { heading: 'Loan Term / Loan Category', description: 'The graph illustrates the distribution of loans across different loan types (Conventional, FHA, VA, and USDA) based on loan terms. It helps Brokers, MLOs, and MLPs analyze the distribution of loan types, assess risk profiles, and track trends in borrower preferences.' },
            ],
        },
    ];


    const initialTabsForMlp = [

        {
            id: 1,
            name: 'Mortgage Originator KPIs',
            content: [
                {
                    heading: 'Monthly Loan Product Analysis',
                    description: 'This reports gives analysis of monthly productivity of loans bifurcated on basis of Loan Terms & Loan Types.'
                },

                {
                    heading: 'Application Approval Rate',
                    description: 'The report tracks the monthly application approval rate for brokers. It provides the number of applications submitted, the number of applications approved, and the approval rate as a percentage. This helps measure the efficiency and success rate of loan applications submitted through brokers in a particular state.'
                },
                {
                    heading: 'Mortgage Loan Declined Rate',
                    description: 'This report calculates the rejection rate for mortgage loan applications, displaying the proportion of applications rejected out of the total submitted across different loan types, roles (brokers), and time periods (monthly). It enables management to track rejection patterns and help identify bottlenecks in the loan approval process.'
                },
                {
                    heading: 'Incomplete Loan Application Rate',
                    description: 'This report highlights trends in incomplete loan applications across brokers, states, and months. It provides stakeholders with insights into potential areas requiring process improvement or borrower support. By aggregating data and visualizing incomplete rates, decision-makers can take targeted actions to reduce inefficiencies.'

                },
                {
                    heading: 'Average Mortgage Type',
                    description: 'This report helps assess the popularity and distribution of various mortgage types by calculating their average contribution to total applications over time or within specific groups.'

                },

                {
                    heading: 'Abandoned Loan Rate',
                    description: 'This report tracks the rate of abandoned loans (Withdrawn or Closed without funding) as a percentage of total loan applications. It helps identify inefficiencies in the loan process by highlighting patterns in loan abandonment across roles, states, or loan types.'


                },
                {
                    heading: 'Average Loan Value',
                    description: `The Average Loan Value of Mortgage Loan Applications measures the average loan amount processed over a rolling time period (e.g., 30, 60, or 90 days).
This report helps identify trends in loan sizes, analyze market demand, and evaluate the impact of loan values on business performance.`


                },
                {
                    heading: 'Average Life Cycle of Mortgage Loans',
                    description: 'The Average Life Cycle of Mortgage Loan Applications shows how many days, on average, it takes to process a mortgage application from when it’s submitted to when it’s approved. It helps track how efficient the loan approval process is over a rolling time period of months.'


                },
                {
                    heading: 'Average Number of Conditions per Loan',
                    description: 'This report calculates the average number of conditions required per loan across various segments such as state, loan officer, loan type, and time period. It helps identify trends and bottlenecks in the underwriting process, providing insights for streamlining loan approval.'


                }



            ],
        },
        {
            id: 2,
            name: 'Mortgage Call Report',
            content: [
                { heading: 'Loan Category', description: 'This report provides an overview of loans based on their type, such as Conventional, FHA, VA, USDA, or other classifications. It helps Brokers, MLOs, and MLPs analyze the distribution of loan types, assess risk profiles, and track trends in borrower preferences.' },
                { heading: 'Loan Term', description: 'This report categorizes loans based on their repayment terms, such as 15-year, 20-year, 30-year, or custom terms. It helps Brokers, MLOs, and MLPs understand borrower preferences, evaluate long-term financial commitments, and make strategic decisions on loan offerings.' },
                { heading: 'Loan Category/Loan Term', description: 'The graph illustrates the distribution of loans across different loan types (Conventional, FHA, VA, and USDA) based on loan terms. It helps Brokers, MLOs, and MLPs analyze the distribution of loan types, assess risk profiles, and track trends in borrower preferences.' },
                { heading: 'Loan Term / Loan Category', description: 'The graph illustrates the distribution of loans across different loan types (Conventional, FHA, VA, and USDA) based on loan terms. It helps Brokers, MLOs, and MLPs analyze the distribution of loan types, assess risk profiles, and track trends in borrower preferences.' },
            ],
        },
    ];

    const [activeTab, setActiveTab] = useState(1);
    const [showChart, setShowChart] = useState(false);
    const [activeComponent, setActiveComponent] = useState(null);

    let profileInfo = useRecoilValue(profileState);
    const selectedTabs = profileInfo?.role === 'MLP' ? initialTabsForMlp : initialTabs;
    const activeContent = selectedTabs.find(tab => tab.id === activeTab)?.content || [];

    // const activeContent = initialTabs.find(tab => tab.id === activeTab)?.content || [];

    const handleBack = () => {
        setShowChart(false);
    };



    return (
        <div>

            {showChart ? (


                <ChartContainer
                    activeComponent={activeComponent}
                    onBack={handleBack}
                    selectedUserFromAdmin={selectedUserFromAdmin}
                    accessFromAdmin={accessFromAdmin}

                />

            ) : (

                <>
                    <Box mb={3}>
                        <Typography variant="h3">
                            Loan performance analysis dashboard
                        </Typography>
                    </Box>

                    <TabList tabs={selectedTabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    <GridDisplay
                        content={activeContent}
                        handleButtonClick={(index) => {
                            const activeTabName = selectedTabs.find(tab => tab.id === activeTab)?.name;
                            handleGridButtonClick(index, activeContent, setActiveComponent, activeTabName);
                            setShowChart(true);
                        }}
                    />
                </>
            )}
        </div>
    );


};

export default DashboardMain;
