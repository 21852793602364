import React from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import DownloadChartWrapper from '../DownloadChartWrapper';

const BAR_SIZE = 25;

const ChartAbandonedLoanRate = ({
    data,
    xAxisLabel = '',
    yAxisLeftLabel = '# of Loans',
    yAxisRightLabel = 'Rate (%)',
    bar1Key = 'rejectedCount',
    bar1Color = '#8884d8',
    bar1Name = '# of Loans Rejected',
    bar2Key = 'fundedCount',
    bar2Color = '#82ca9d',
    bar2Name = '# of Loans Funded',
    lineKey = 'abandonedRate',
    lineColor = '#ff7300',
    lineName = 'Abandoned Loan Rate',
}) => {

    const chartData = Object.values(
        data.reduce((acc, item) => {
            const head = item.head;

            if (!acc[head]) {
                acc[head] = {
                    name: new Date(`${head}-01`).toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
                    fundedCount: 0,
                    rejectedCount: 0,
                    abandonedRate: 0,
                };
            }


            acc[head].fundedCount += item.value2 || 0;
            acc[head].rejectedCount += item.value1 || 0;

            return acc;
        }, {})
    ).map(group => ({
        ...group,
        abandonedRate:
            group.fundedCount + group.rejectedCount > 0
                ? ((group.rejectedCount / (group.fundedCount + group.rejectedCount)) * 100).toFixed(2)
                : 0,
    }));


    const chartComponent = (
        <ResponsiveContainer height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" label={{ value: xAxisLabel, position: 'bottom', offset: -10 }} />
                <YAxis
                    yAxisId="left"
                    label={{
                        value: yAxisLeftLabel,
                        angle: -90,
                        position: 'insideLeft',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                    allowDecimals={false}
                    tickFormatter={(value) => Math.round(value)}
                />
                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={value => `${value}%`}
                    domain={[0, 100]}
                    label={{
                        value: yAxisRightLabel,
                        angle: 90,
                        position: 'insideRight',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                />
                <Tooltip
                    content={({ payload }) => {
                        if (payload && payload.length) {
                            const { fundedCount, rejectedCount, abandonedRate } = payload[0].payload;
                            return (
                                <div
                                    style={{
                                        backgroundColor: '#fff',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <p><strong>{payload[0].payload.name}</strong></p>
                                    <p>Rejected Loans: {rejectedCount}</p>
                                    <p>Funded Loans: {fundedCount}</p>
                                    <p>Abandoned Loan Rate: {abandonedRate}%</p>
                                </div>
                            );
                        }
                        return null;
                    }}
                />
                <Legend />
                <Bar
                    yAxisId="left"
                    dataKey="rejectedCount"
                    fill={bar1Color}
                    barSize={BAR_SIZE}
                    name={bar1Name}
                />
                <Bar
                    yAxisId="left"
                    dataKey="fundedCount"
                    fill={bar2Color}
                    barSize={BAR_SIZE}
                    name={bar2Name}
                />
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="abandonedRate"
                    stroke={lineColor}
                    strokeWidth={3}
                    dot={{ r: 6, fill: lineColor }}
                    activeDot={{ r: 8 }}
                    name={lineName}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <DownloadChartWrapper
            chartComponent={chartComponent}
            data={chartData}
            fileName="LoansChart"
        />
    );
};

export default ChartAbandonedLoanRate;
