
import React from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    Label,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import DownloadChartWrapper from '../DownloadChartWrapper';


const CustomStackedBarChart = ({ onBack, data }) => {
    const formatValue = (value) => (value >= 1000 ? `${(value / 1000).toFixed(0)}K` : value.toString());

    const groupedData = Object.values(
        data.reduce((acc, item) => {
            const { head } = item;
            if (!acc[head]) {
                acc[head] = { ...item, value1: 0, value2: 0 };
            }
            acc[head].value1 += item.value1;
            acc[head].value2 += item.value2;
            return acc;
        }, {})
    );

    const customTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px' }}>
                    <p><strong>{payload[0].payload.head}</strong></p>
                    <p># of Loans: {Math.round(payload[0].value)}</p>
                    <p>Loan Amount: $ {formatValue(payload[1].value)}</p>
                </div>
            );
        }
        return null;
    };

    const customLegend = (value) => (value === 'value1' ? '# of Loans' : 'Loan Amount');

    const rightAxisFormatter = (value) => formatValue(value);

    const chartComponent = (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={groupedData}
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                barSize={25}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="head" />


                <YAxis
                    yAxisId="left"
                    allowDecimals={false}
                    tickFormatter={(value) => Math.round(value)}
                >
                    <Label value="# of Loans" angle={-90} position="left" style={{ textAnchor: 'middle', fontWeight: 'bold' }} />
                </YAxis>


                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={rightAxisFormatter}
                    domain={[0, Math.ceil(Math.max(...groupedData.map(d => d.value2)) / 1000) * 1000]}
                >
                    <Label value="Loan Amount in $" angle={90} position="right" style={{ textAnchor: 'middle', fontWeight: 'bold' }} />
                </YAxis>

                <Tooltip content={customTooltip} />
                <Legend formatter={customLegend} />


                <Bar dataKey="value1" stackId="a" fill="#8884d8" yAxisId="left">
                    <LabelList dataKey="value1" position="top" formatter={(value) => Math.round(value)} />
                </Bar>


                <Bar dataKey="value2" stackId="a" fill="#82ca9d" yAxisId="right">
                    <LabelList dataKey="value2" position="top" formatter={formatValue} />
                </Bar>

            </BarChart>
        </ResponsiveContainer>
    );

    return <DownloadChartWrapper chartComponent={chartComponent} data={groupedData} fileName="LoansChart" />;
};

export default CustomStackedBarChart;
