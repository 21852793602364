
import React from 'react';
import { Button } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DownloadChartWrapper from '../DownloadChartWrapper';

const CustomBarChart = ({
    data,
    xAxisKey = 'head',
    xAxisLabel = '# of Leads',
    yAxisLabel = '# of Leads',
    barKey = 'value1',
    barColor = '#8884d8',
    onBack
}) => {



    const processedData = Object.values(
        data.reduce((acc, item) => {
            if (!acc[item[xAxisKey]]) {
                acc[item[xAxisKey]] = {
                    [xAxisKey]: item[xAxisKey],
                    [barKey]: 0,
                };
            }
            acc[item[xAxisKey]][barKey] += item[barKey] || 0;
            return acc;
        }, {})
    );



    if (!processedData || processedData.length === 0) {
        return <div>No valid data available to display.</div>;
    }

    const chartComponent = (
        <div style={{ width: '100%', height: '400px' }}>

            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    data={processedData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={xAxisKey}

                    />
                    <YAxis
                        label={{
                            value: yAxisLabel,
                            angle: -90,
                            position: 'insideLeft',
                            style: { fontWeight: 'bold' }
                        }} allowDecimals={false}
                        tickFormatter={(value) => Math.round(value)}

                    />

                    <Tooltip
                        formatter={(value) => `${value}`}
                    />
                    <Legend />
                    <Bar
                        dataKey={barKey}
                        fill={barColor}
                        barSize={25}
                        name={xAxisLabel}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );

    return (
        <DownloadChartWrapper
            chartComponent={chartComponent}
            data={processedData}
            fileName="LoansChart"
        />
    );
};

export default CustomBarChart;
