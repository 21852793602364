
export const handleGridButtonClick = (index, content, setActiveComponent, activeTabName) => {




    const selectedContent = content[index]?.heading;

    if (selectedContent) {

        setActiveComponent(selectedContent);
    } else {
        console.warn('No content found or heading is undefined for the selected index');
    }
};
