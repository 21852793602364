
import React from 'react';
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
} from 'recharts';
import DownloadChartWrapper from '../DownloadChartWrapper';

const BAR_SIZE = 25;

const CustomGroupedBarChartForLeads = ({
    data,
    xAxisLabel = '',
    yAxisLeftLabel = '# of Loans',
    yAxisRightLabel = 'Rate (%)',
    bar1Key = 'approvedWithConditions',
    bar1Color = '#82ca9d',
    bar1Name = '# of Loans Approved with Conditions',
    bar2Key = 'notFunded',
    bar2Color = '#8884d8',
    bar2Name = '# of Loans Not Funded',
    lineKey = 'approvalRate',
    lineColor = '#ff7300',
    lineName = 'Approval Rate',
    ...props
}) => {


    const chartData = Object.values(
        data.reduce((acc, item) => {
            const head = item.head;
            const loanCode = item.loanCode;

            if (!acc[head]) {
                acc[head] = {
                    name: new Date(head).toLocaleDateString('en-US', { month: 'short', year: '2-digit' }),
                    [bar1Key]: 0,
                    uniqueLoans: new Set(),
                };
            }

            acc[head][bar1Key] += item.value1 || 0;
            acc[head].uniqueLoans.add(loanCode);

            return acc;
        }, {})
    ).map(group => ({
        ...group,
        [bar2Key]: group.uniqueLoans.size,
        [lineKey]: group[bar1Key] > 0 ? ((group[bar1Key] / group.uniqueLoans.size) * 100).toFixed(2) : 0,
    }));
    const chartComponent = (
        <ResponsiveContainer height={400}>
            <ComposedChart
                data={chartData}
                margin={{
                    top: 20,
                    right: 40,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" label={{ value: xAxisLabel, position: 'bottom', offset: -10 }} />

                <YAxis
                    yAxisId="left"
                    label={{
                        value: yAxisLeftLabel,
                        angle: -90,
                        position: 'insideLeft',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                    allowDecimals={false}
                    tickFormatter={(value) => Math.round(value)}
                />

                <YAxis
                    yAxisId="right"
                    orientation="right"
                    tickFormatter={value => `${value}%`}
                    domain={[0, 100]}
                    label={{
                        value: yAxisRightLabel,
                        angle: 90,
                        position: 'insideRight',
                        style: { textAnchor: 'middle', fontWeight: 'bold' },
                    }}
                />
                <Tooltip
                    formatter={(value, name, props) => {
                        if (name === lineName) {
                            return [`${value} %`, name];
                        } else if (props.dataKey === bar1Key || props.dataKey === bar2Key) {
                            return [value, name];
                        }
                    }}
                    content={({ payload, label }) => {
                        if (!payload || payload.length === 0) return null;

                        const dataPoint = payload[0]?.payload;
                        return (
                            <div className="custom-tooltip" style={{ background: '#fff', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
                                <p><strong>{label}</strong></p>
                                <p style={{ color: bar1Color }}>{bar1Name}: {dataPoint[bar1Key]}</p>

                                <p style={{ color: bar2Color }}># of Total Loans: {dataPoint[bar2Key]}</p>

                                <p style={{ color: lineColor }}>{lineName}: {dataPoint[lineKey]}%</p>
                            </div>
                        );
                    }}
                />
                <Legend />
                <Bar
                    yAxisId="left"
                    dataKey={bar1Key}
                    fill={bar1Color}
                    barSize={BAR_SIZE}
                    name={bar1Name}
                />
                <Bar
                    yAxisId="left"
                    dataKey={bar2Key}
                    fill={bar2Color}
                    barSize={BAR_SIZE}
                    name={bar2Name}
                />
                <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey={lineKey}
                    stroke={lineColor}
                    strokeWidth={3}
                    dot={{ r: 6, fill: lineColor }}
                    activeDot={{ r: 8 }}
                    name={lineName}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <DownloadChartWrapper
            chartComponent={chartComponent}
            data={chartData}
            fileName="LoansChart"
        />
    );
};

export default CustomGroupedBarChartForLeads;
