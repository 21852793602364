// // import { Box, Button, Grid, MenuItem, Typography, } from "@mui/material";
// // import React, { useEffect, useMemo, useState } from "react";
// // import ConfirmationModel from "../../helpers/modal/confirm-modal";
// // import CustomInput from "../../helpers/custom-input";
// // import CustomReactMaterialTable from "../../helpers/table/react-material-table";
// // import DialogSuccesMsg from "../../helpers/modal/dialog-succes-msg";
// // import { DialogCommonHooks } from "../../helpers/modal/modal";
// // import { useFormik } from "formik";
// // import useInput from "../../helpers/use-input";
// // import { API } from "../../services/api-config";
// // import { getApiCallDynamic, getApiCallDynamicWithHeader } from "../../services/api-service";
// // import Select from "react-dropdown-select";
// // import CustomDropDown from "../../helpers/dropdown/custom-dropdown";

// // // function Merge(props) {
// // //   return (
// // //     <>
// // //     </>
// // //   )
// // // }
// // // export default Merge;

// // function Merge(props) {
// //   const [successModalOpen, setSuccessModalOpen] = useState(false);
// //   const [msg, setMsg] = useState(' ');
// //   const [error, setError] = useState(false);
// //   const [mloOwnerList, setMloOwnerList] = useState([])



// //   // useEffect(() => {


// //   //   getApiCallDynamic({ path: API.mergerMLOOwnerList }).then(
// //   //     (res) => {
// //   //       if (res?.status === 200) {
// //   //         setMloOwnerList(res.data.data);
// //   //       }
// //   //     }


// //   //   );



// //   // }, []);
// //   useEffect(() => {
// //     getApiCallDynamic({ path: API.mergerMLOOwnerList })
// //       .then((res) => {
// //         console.log("Full API Response:", res);


// //         console.log("Expected Data:", res.data); // Log expected array
// //         setMloOwnerList(res.data || []); // Ensure a fallback to an empty array

// //       })
// //       .catch((error) => {
// //         console.error("API Fetch Error:", error);
// //       });
// //   }, []);









// //   let [accountNumber] = useInput({
// //     data: '',
// //     initial: "",
// //   });
// //   function isNumeric(str) {
// //     if (typeof str != "string") return false
// //     return !isNaN(str) &&
// //       !isNaN(parseFloat(str))
// //   }
// //   const getError = () => {
// //     console.log(typeof msg, msg.trim().length)
// //     setError(!(isNumeric(msg) && msg.trim().length));
// //   };

// //   const handleSuccessOpen = () => {
// //     if (msg.length) {
// //       setSuccessModalOpen(true);
// //     }
// //   };
// //   const handleClose = () => {
// //     setSuccessModalOpen(false);
// //     handleModalClose();
// //   };

// //   const [openModal, setOpenModal] = useState(false);
// //   const handleOpenModal = () => {
// //     setOpenModal(true);
// //   };
// //   const handleModalClose = () => {
// //     setOpenModal(false);
// //   };

// //   const columns = useMemo(
// //     () => [
// //       {
// //         accessorKey: "accountNumber",
// //         header: "Account Number",
// //         enableColumnActions: false,
// //         enableSorting: false,
// //       },
// //       {
// //         accessorKey: "firstName",
// //         header: "Name",
// //         enableColumnActions: false,
// //         enableSorting: false,
// //       },
// //       {
// //         accessorKey: "state",
// //         header: "State",
// //         enableColumnActions: false,
// //         enableSorting: false,
// //       },
// //       {
// //         accessorKey: "mergeStatus",
// //         header: "Merge Status",
// //         enableColumnActions: false,
// //         enableSorting: false,
// //       },
// //       {
// //         accessorKey: "dateRequested",
// //         header: "Date Requested",
// //         enableColumnActions: false,
// //         enableSorting: false,
// //       },

// //       //end
// //     ],
// //     []
// //   );
// //   console.log(" Mlow owner list fetched " + JSON.stringify(mloOwnerList, null, 2))
// //   let data = [
// //     {
// //       accountNumber: "12345",
// //       name: "ABC",
// //       state: "AB",
// //       mergeStatus: "Pending",
// //       dateRequested: "Oct 28, 2022",
// //     },
// //     {
// //       accountNumber: "56475",
// //       name: "ABC",
// //       state: "AB",
// //       mergeStatus: "Merge",
// //       dateRequested: "Sep 09, 2022",
// //     },
// //     {
// //       accountNumber: "73373",
// //       name: "ABC",
// //       state: "AB",
// //       mergeStatus: "Decline",
// //       dateRequested: "Oct 28, 2022",
// //     },
// //   ];
// //   const [selectedOwner, setSelectedOwner] = useState(null);
// //   const handleSetMsg = (e) => {
// //     setMsg(e.target.value)
// //   }

// //   const handleSelectChange = (event) => {
// //     setSelectedOwner(event.target.value);
// //   };

// //   return (
// //     <>
// //       <Grid container direction={"row"}>
// //         <Grid item sm={12} md={5} my={1}>
// //           <Typography variant="h3" marginBottom={'8px'}>Merge</Typography>
// //           <Typography variant="p2">
// //             Account number to invite for the merge
// //           </Typography>
// //         </Grid>

// //       </Grid>
// //       <Grid item sm={12} md={12} my={1} overflow={'auto'}>
// //         <CustomReactMaterialTable
// //           endPoint="/api/users"
// //           columns={columns}
// //           staticLoad={true}
// //           staticData={mloOwnerList}
// //           enableToolbarInternalActions={false}
// //           renderTopToolbarCustomActions={(table) => (
// //             <Box my={1} display="flex" direction="row" justifyContent="end">
// //               <Button variant="outlined" onClick={() => handleOpenModal()}>
// //                 Add More
// //               </Button>
// //             </Box>
// //           )}
// //         />
// //       </Grid>
// //       {/* Marge Req modal */}
// //       <DialogCommonHooks
// //         title="Account number to invite"
// //         show={openModal}
// //         handleClose={handleModalClose}
// //         body={
// //           <Grid container direction={"column"} my={2} spacing={2}>
// //             <Grid item>
// //               <CustomInput
// //                 label="Account Number"
// //                 onChange={(e) => { getError(); handleSetMsg(e) }}
// //                 required
// //                 fullWidth
// //                 marginBottom={0}
// //                 placeholder="12345"
// //               />
// //               {error &&
// //                 <Typography fontSize={'12px'} color={"error"}>Please Enter Account Number</Typography>
// //               }
// //               <Select fullWidth value={selectedOwner || ""} onChange={handleSelectChange}>
// //                 {mloOwnerList?.map((owner) => (
// //                   <MenuItem key={owner.userCode} value={owner}>
// //                     {`${owner.phone}, ${owner.firstName} ${owner.lastName}, ${owner.stateLicenses[0]?.state || "N/A"}`}
// //                   </MenuItem>
// //                 ))}
// //               </Select>
// //             </Grid>
// //             <Grid item textAlign={{ xs: 'center', md: 'right' }}>
// //               <ConfirmationModel
// //                 disabled={!(isNumeric(msg) && msg.trim().length)}
// //                 body={<Button variant="contained" onClick={() => getError()}>Send Merge Request</Button>}
// //                 handleSubmit={() => handleSuccessOpen()}
// //                 message={"Are you sure you want to send the merge request?"}
// //                 title={"Confirmation"}
// //               />
// //             </Grid>
// //             <CustomDropDown
// //               label="MLO Owner List"
// //               {...field}
// //               field={field}
// //               fullWidth
// //               required
// //               variant="outlined"
// //               option={mloOwnerList.map((owner) => ({
// //                 id: owner.userCode,
// //                 value: `${owner.phone}, ${owner.firstName} ${owner.lastName}, ${owner.stateLicenses[0]?.state || "N/A"}`
// //               }))}
// //               error={!!errors.selectedMloOwner}
// //               helperText={errors.selectedMloOwner?.message || ""}
// //               changeCallBack={({ id, value }) => {
// //                 field.onChange(id); // Updates react-hook-form state
// //                 setSelectedOwner(id); // Updates local state
// //                 console.log("Selected MLO Owner:", id, value);
// //               }}
// //             />

// //           </Grid>
// //         }
// //       />
// //       {/* Marge Req success modal */}
// //       <DialogCommonHooks
// //         show={successModalOpen}
// //         handleClose={handleClose}
// //         body={
// //           <DialogSuccesMsg
// //             description="Thank You! Request has been sent."
// //             handleSubmit={handleSuccessOpen}
// //             handleClose={handleClose}
// //           />
// //         }
// //       />
// //     </>
// //   );
// // }

// // export default Merge;
// import { Box, Button, Grid, MenuItem, Typography, Select } from "@mui/material";
// import React, { useEffect, useMemo, useState } from "react";
// import ConfirmationModel from "../../helpers/modal/confirm-modal";
// import CustomInput from "../../helpers/custom-input";
// import CustomReactMaterialTable from "../../helpers/table/react-material-table";
// import DialogSuccesMsg from "../../helpers/modal/dialog-succes-msg";
// import { DialogCommonHooks } from "../../helpers/modal/modal";
// import { useFormik } from "formik";
// import useInput from "../../helpers/use-input";
// import { API } from "../../services/api-config";
// import { getApiCallDynamic } from "../../services/api-service";
// import CustomDropDown from "../../helpers/dropdown/custom-dropdown";

// function Merge() {
//   const [successModalOpen, setSuccessModalOpen] = useState(false);
//   const [msg, setMsg] = useState("");
//   const [error, setError] = useState(false);
//   const [mloOwnerList, setMloOwnerList] = useState([]);
//   const [selectedOwner, setSelectedOwner] = useState(null);
//   const [openModal, setOpenModal] = useState(false);

//   useEffect(() => {
//     getApiCallDynamic({ path: API.mergerMLOOwnerList })
//       .then((res) => {
//         console.log("Full API Response:", res);
//         setMloOwnerList(res?.data || []); // Ensure a fallback to an empty array
//       })
//       .catch((error) => {
//         console.error("API Fetch Error:", error);
//       });
//   }, []);

//   const isNumeric = (str) => {
//     return typeof str === "string" && !isNaN(str) && !isNaN(parseFloat(str));
//   };

//   const getError = () => {
//     setError(!(isNumeric(msg) && msg.trim().length > 0));
//   };

//   // const handleSuccessOpen = () => {
//   //   if (msg.length) {
//   //     setSuccessModalOpen(true);
//   //   }
//   // };

//   const handleSuccessOpen = () => {
//     if (msg.length) {
//       console.log("Selected Owner Details:", selectedOwner); // Log full details
//       setSuccessModalOpen(true);
//     }
//   };


//   const handleClose = () => {
//     setSuccessModalOpen(false);
//     handleModalClose();
//   };

//   const handleOpenModal = () => {
//     setOpenModal(true);
//   };

//   const handleModalClose = () => {
//     setOpenModal(false);
//   };

//   const handleSetMsg = (e) => {
//     setMsg(e.target.value);
//     getError();
//   };

//   // const handleSelectChange = (event) => {
//   //   setSelectedOwner(event.target.value);
//   // };

//   const handleSelectChange = (event) => {
//     const selected = mloOwnerList.find(owner => owner.userCode === event.target.value);
//     setSelectedOwner(selected);
//   };


//   const columns = useMemo(
//     () => [
//       { accessorKey: "accountNumber", header: "Account Number" },
//       { accessorKey: "firstName", header: "Name" },
//       { accessorKey: "state", header: "State" },
//       { accessorKey: "mergeStatus", header: "Merge Status" },
//       { accessorKey: "dateRequested", header: "Date Requested" },
//     ],
//     []
//   );

//   return (
//     <>
//       <Grid container direction={"row"}>
//         <Grid item sm={12} md={5} my={1}>
//           <Typography variant="h3" marginBottom={"8px"}>
//             Merge
//           </Typography>
//           <Typography variant="p2">
//             Account number to invite for the merge
//           </Typography>
//         </Grid>
//       </Grid>
//       <Grid item sm={12} md={12} my={1} overflow={"auto"}>
//         <CustomReactMaterialTable
//           endPoint="/api/users"
//           columns={columns}
//           staticLoad={true}
//           staticData={mloOwnerList}
//           enableToolbarInternalActions={false}
//           renderTopToolbarCustomActions={() => (
//             <Box my={1} display="flex" justifyContent="end">
//               <Button variant="outlined" onClick={handleOpenModal}>
//                 Add More
//               </Button>
//             </Box>
//           )}
//         />
//       </Grid>

//       {/* Merge Request Modal */}
//       <DialogCommonHooks
//         title="Account number to invite"
//         show={openModal}
//         handleClose={handleModalClose}
//         body={
//           <Grid container direction={"column"} my={2} spacing={2}>
//             <Grid item>
//               {/* <CustomInput
//                 label="Account Number"
//                 onChange={handleSetMsg}
//                 required
//                 fullWidth
//                 placeholder="12345"
//               />
//               {error && (
//                 <Typography fontSize={"12px"} color={"error"}>
//                   Please Enter a Valid Account Number
//                 </Typography>
//               )} */}

//               {/* Dropdown Selection */}
//               {/* <Select
//                 fullWidth
//                 value={selectedOwner || ""}
//                 onChange={handleSelectChange}
//                 displayEmpty
//               >
//                 <MenuItem value="" disabled>
//                   Select Owner
//                 </MenuItem>
//                 {mloOwnerList?.map((owner) => (
//                   <MenuItem key={owner.userCode} value={owner}>
//                     {`${owner.phone}, ${owner.firstName} ${owner.lastName}, ${owner.stateLicenses[0]?.state || "N/A"
//                       }`}
//                   </MenuItem>
//                 ))}
//               </Select> */}
//               <Select
//                 fullWidth
//                 value={selectedOwner?.userCode || ""}
//                 onChange={handleSelectChange}
//                 displayEmpty
//               >
//                 <MenuItem value="" disabled>Select Owner</MenuItem>
//                 {mloOwnerList?.map((owner) => (
//                   <MenuItem key={owner.userCode} value={owner.userCode}>
//                     {`${owner.phone}, ${owner.firstName} ${owner.lastName}, ${owner.stateLicenses[0]?.state || "N/A"}`}
//                   </MenuItem>
//                 ))}
//               </Select>

//             </Grid>

//             <Grid item textAlign={{ xs: "center", md: "right" }}>
//               <ConfirmationModel
//                 disabled={!(isNumeric(msg) && msg.trim().length)}
//                 body={
//                   <Button variant="contained" onClick={getError}>
//                     Send Merge Request
//                   </Button>
//                 }
//                 handleSubmit={handleSuccessOpen}
//                 message={"Are you sure you want to send the merge request?"}
//                 title={"Confirmation"}
//               />
//             </Grid>
//           </Grid>
//         }
//       />

//       {/* Merge Request Success Modal */}
//       <DialogCommonHooks
//         show={successModalOpen}
//         handleClose={handleClose}
//         body={
//           <DialogSuccesMsg
//             description="Thank You! Request has been sent."
//             handleSubmit={handleSuccessOpen}
//             handleClose={handleClose}
//           />
//         }
//       />
//     </>
//   );
// }

// export default Merge;
import { Box, Button, Grid, MenuItem, Typography, Select } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import ConfirmationModel from "../../helpers/modal/confirm-modal";
import CustomReactMaterialTable from "../../helpers/table/react-material-table";
import DialogSuccesMsg from "../../helpers/modal/dialog-succes-msg";
import { DialogCommonHooks } from "../../helpers/modal/modal";
import { API } from "../../services/api-config";
import { getApiCallDynamic } from "../../services/api-service";

function Merge() {
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [mloOwnerList, setMloOwnerList] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getApiCallDynamic({ path: API.mergerMLOOwnerList })
      .then((res) => {
        console.log("Full API Response:", res);
        setMloOwnerList(res?.data || []);
      })
      .catch((error) => {
        console.error("API Fetch Error:", error);
      });
  }, []);

  const handleSuccessOpen = () => {
    if (selectedOwner) {
      console.log("Selected Owner Details:", selectedOwner);
      setSuccessModalOpen(true);
    }
  };

  const handleClose = () => {
    setSuccessModalOpen(false);
    handleModalClose();
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleSelectChange = (event) => {
    const selected = mloOwnerList.find(owner => owner.userCode === event.target.value);
    setSelectedOwner(selected);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "accountNumber",
        header: "Account Number",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "firstName",
        header: "Name",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "state",
        header: "State",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "mergeStatus",
        header: "Merge Status",
        enableColumnActions: false,
        enableSorting: false,
      },
      {
        accessorKey: "dateRequested",
        header: "Date Requested",
        enableColumnActions: false,
        enableSorting: false,
      },

      //end
    ],
    []
  );

  const data = []
  return (
    <>
      <Grid container direction={"row"}>
        <Grid item sm={12} md={5} my={1}>
          <Typography variant="h3" marginBottom={"8px"}>Merge</Typography>
          <Typography variant="body2">Account number to invite for the merge</Typography>
        </Grid>
      </Grid>

      <Grid item sm={12} md={12} my={1} overflow={"auto"}>
        <CustomReactMaterialTable
          endPoint="/api/users"
          columns={columns}
          staticLoad={true}
          staticData={data}
          enableToolbarInternalActions={false}
          renderTopToolbarCustomActions={() => (
            <Box my={1} display="flex" justifyContent="end">
              <Button variant="outlined" onClick={handleOpenModal}>
                Merge More
              </Button>
            </Box>
          )}
        />
      </Grid>

      {/* Merge Request Modal */}
      <DialogCommonHooks
        title="Account number to invite"
        show={openModal}
        handleClose={handleModalClose}
        body={
          <Grid container direction={"column"} my={2} spacing={2}>
            <Grid item>
              <Select
                fullWidth
                value={selectedOwner?.userCode || ""}
                onChange={handleSelectChange}
                displayEmpty
              >
                <MenuItem value="" disabled>Select Owner</MenuItem>
                {mloOwnerList?.map((owner) => (
                  <MenuItem key={owner.userCode} value={owner.userCode}>
                    {`${owner.nmlsId}, ${owner.firstName} ${owner.lastName}, ${owner.stateLicenses[0]?.state || "N/A"}`}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item textAlign={{ xs: "center", md: "right" }}>
              <ConfirmationModel
                disabled={!selectedOwner}
                body={
                  <Button variant="contained">
                    Send Merge Request
                  </Button>
                }
                handleSubmit={handleSuccessOpen}
                message={"Are you sure you want to send the merge request?"}
                title={"Confirmation"}
              />
            </Grid>
          </Grid>
        }
      />

      {/* Merge Request Success Modal */}
      <DialogCommonHooks
        show={successModalOpen}
        handleClose={handleClose}
        body={
          <DialogSuccesMsg
            description="Thank You! Request has been sent."
            handleSubmit={handleSuccessOpen}
            handleClose={handleClose}
          />
        }
      />
    </>
  );
}

export default Merge;
