
import React, { useState, useEffect } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const Chart1 = ({ onBack, data }) => {



    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {

        let filtered = data;

        setFilteredData(filtered);
    }, [data]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display="flex" flexDirection="column" alignItems="center" padding={2}>

                {/* Pie Chart */}
                <ResponsiveContainer width="100%" height={400}>
                    <PieChart>
                        <Pie data={filteredData} dataKey="value" outerRadius={150} fill="#8884d8" label>
                            {filteredData?.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip />
                        <Legend />
                    </PieChart>
                </ResponsiveContainer>


            </Box>
        </LocalizationProvider>
    );
};

export default Chart1;
